var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('KTPortlet',{attrs:{"title":"View Caller Detail Records","data-app":"cdrList"}},[_c('template',{slot:"body"},[(_vm.cdrs === null)?_c('Loader',{attrs:{"inContent":true,"text":"Loading Calls"}},[_vm._v("Loading...")]):(_vm.cdrs.length === 0)?_c('div',{staticStyle:{"text-align":"center"}},[_c('h2',[_vm._v("Sit back, relax and make some calls")]),_c('h5',[_vm._v(" Your calls will appear here once you have made/received some ")]),_c('personIconImage',{staticStyle:{"max-width":"500px","margin-top":"-50px"}})],1):[_c('b-table-simple',{attrs:{"responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{attrs:{"sticky-column":""}},[_vm._v("Caller")]),_c('b-th',[_vm._v("Destination")]),_c('b-th',[_vm._v("Direction")]),_c('b-th',[_vm._v("Date")]),_c('b-th',[_vm._v("CQI")]),_c('b-th',[_vm._v("Status")]),_c('b-th')],1)],1),_c('b-tbody',_vm._l((_vm.cdrs),function(cdr){return _c('b-tr',{key:cdr.id,attrs:{"callId":cdr.id}},[_c('b-td',[(
                      !_vm.$tools.isNullOrUndefined(
                        cdr.cdr.variables.directory_full_name
                      )
                    )?[_vm._v("["+_vm._s(cdr.cdr.variables.directory_full_name)+"] ")]:_vm._e(),(
                      !_vm.$tools.isNullOrUndefined(
                        cdr.cdr.variables.caller_id_name
                      ) &&
                      cdr.cdr.variables.caller_id_name !== '' &&
                      cdr.cdr.variables.caller_id_name !==
                        cdr.cdr.variables.caller_id_number
                    )?[_vm._v(_vm._s(cdr.cdr.variables.caller_id_number)+" ("+_vm._s(cdr.cdr.variables.caller_id_name)+")")]:(
                      !_vm.$tools.isNullOrUndefined(
                        cdr.cdr.variables.effective_caller_id_number
                      ) &&
                      cdr.cdr.variables.effective_caller_id_number !== '' &&
                      cdr.cdr.variables.effective_caller_id_number !==
                        cdr.cdr.variables.caller_id_number
                    )?[_vm._v(_vm._s(cdr.cdr.variables.caller_id_number)+" ("+_vm._s(cdr.cdr.variables.effective_caller_id_number)+")")]:[_vm._v(_vm._s(cdr.cdr.variables.caller_id_number || cdr.cdr.variables.last_sent_callee_id_name || "*Private Number*"))]],2),(
                    !_vm.$tools.isNullOrUndefined(
                      cdr.cdr.variables.last_sent_callee_id_number
                    ) &&
                    cdr.cdr.variables.last_sent_callee_id_number !== '' &&
                    cdr.cdr.variables.last_sent_callee_id_number !==
                      cdr.cdr.variables.caller_destination
                  )?_c('b-th',{attrs:{"sticky-column":""}},[_vm._v(_vm._s(cdr.cdr.variables.caller_destination)+" ("+_vm._s(cdr.cdr.variables.last_sent_callee_id_number)+")")]):_c('b-th',{attrs:{"sticky-column":""}},[_vm._v(_vm._s(cdr.cdr.variables.caller_destination || "*Private Number*"))]),_c('b-td',[_vm._v(_vm._s(cdr.cdr.variables.call_direction == "local" ? "internal" : cdr.cdr.variables.call_direction))]),_c('b-td',[_vm._v(_vm._s(_vm.niceFormat(cdr.cdr.variables.start_epoch * 1000)))]),_c('b-td',[(_vm.getCallMOS(cdr) !== null)?_c('v-chip',{style:(`background: var(--${
                      _vm.getCallMOS(cdr).bg
                    }); color: #FFFFFF;`),attrs:{"small":true}},[_vm._v(" "+_vm._s(_vm.getCallMOS(cdr).content)+" ")]):_vm._e()],1),_c('b-td',[(_vm.getCallStatusChip(cdr) !== null)?_c('v-chip',{style:(`background: var(--${
                      _vm.getCallStatusChip(cdr).bg
                    }); color: #FFFFFF;`),attrs:{"small":true}},[_vm._v(" "+_vm._s(_vm.getCallStatusChip(cdr).content.toUpperCase())+" ")]):_vm._e()],1),(cdr.recorded)?_c('b-td',[_c('b-button',{staticStyle:{"margin-left":"10px","padding":"5px 0 5px 6px"},attrs:{"disabled":_vm.busyDownloading,"variant":"primary"},on:{"click":function($event){return _vm.downloadCDR(cdr)}}},[(_vm.busyDownloading === cdr.id)?_c('v-icon',{staticStyle:{"color":"#ffffff"}},[_vm._v("mdi-refresh")]):_c('v-icon',{staticStyle:{"color":"#ffffff"}},[_vm._v("mdi-download")])],1)],1):_c('b-td')],1)}),1)],1),_c('p'),(_vm.totalCount > _vm.perPage)?_c('v-pagination',{attrs:{"align":"center","total-visible":7,"circle":"","length":_vm.knownPageCount,"per-page":_vm.perPage},on:{"input":(page) => _vm.changePage(_vm.$route.params.serviceId, page)},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()]],2)],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }