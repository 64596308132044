<template>
  <div>
    <div>
      <KTPortlet title="View Caller Detail Records" data-app="cdrList">
        <!-- <template v-slot:toolbar>
          <v-autocomplete
            v-if="serviceDomains.length > 1"
            :items="serviceDomains"
            item-text="text"
            item-value="value"
            v-model="selectedServiceId"
            xplaceholder="Start typing to Search"
            label="Cloud PBX"
            @change="updateCDRs(selectedServiceId)"
          ></v-autocomplete>
        </template>-->
        <template slot="body">
          <Loader v-if="cdrs === null" :inContent="true" text="Loading Calls"
            >Loading...</Loader
          >
          <div v-else-if="cdrs.length === 0" style="text-align: center">
            <h2>Sit back, relax and make some calls</h2>
            <h5>
              Your calls will appear here once you have made/received some
            </h5>
            <personIconImage style="max-width: 500px; margin-top: -50px" />
          </div>
          <template v-else>
            <!-- <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th sticky-column>Index</b-th>
                  <b-th>Destination</b-th>
                  <b-th>Direction</b-th>
                  <b-th>Date</b-th>
                  <b-th>CQI</b-th>
                  <b-th>Status</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="cdr in cdrs[35].cdr.callflow"
                  v-bind:key="cdr.profile_index"
                >
                  <b-td>
                    {{ cdr.profile_index }}
                  </b-td>
                  <b-th>{{ cdr.extension.number }}</b-th>
                  <b-th v-if="cdr.caller_profile.originatee !== undefined">{{
                    cdr.caller_profile.originatee.originatee_caller_profiles[0]
                      .destination_number
                  }}</b-th>
                </b-tr>
              </b-tbody>
            </b-table-simple> -->
            <b-table-simple responsive>
              <b-thead>
                <b-tr>
                  <b-th sticky-column>Caller</b-th>
                  <b-th>Destination</b-th>
                  <b-th>Direction</b-th>
                  <b-th>Date</b-th>
                  <b-th>CQI</b-th>
                  <b-th>Status</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="cdr in cdrs"
                  v-bind:key="cdr.id"
                  :callId="cdr.id"
                >
                  <b-td>
                    <template
                      v-if="
                        !$tools.isNullOrUndefined(
                          cdr.cdr.variables.directory_full_name
                        )
                      "
                      >[{{ cdr.cdr.variables.directory_full_name }}]
                    </template>
                    <template
                      v-if="
                        !$tools.isNullOrUndefined(
                          cdr.cdr.variables.caller_id_name
                        ) &&
                        cdr.cdr.variables.caller_id_name !== '' &&
                        cdr.cdr.variables.caller_id_name !==
                          cdr.cdr.variables.caller_id_number
                      "
                      >{{ cdr.cdr.variables.caller_id_number }} ({{
                        cdr.cdr.variables.caller_id_name
                      }})</template
                    >
                    <template
                      v-else-if="
                        !$tools.isNullOrUndefined(
                          cdr.cdr.variables.effective_caller_id_number
                        ) &&
                        cdr.cdr.variables.effective_caller_id_number !== '' &&
                        cdr.cdr.variables.effective_caller_id_number !==
                          cdr.cdr.variables.caller_id_number
                      "
                      >{{ cdr.cdr.variables.caller_id_number }} ({{
                        cdr.cdr.variables.effective_caller_id_number
                      }})</template
                    >
                    <template v-else>{{
                      cdr.cdr.variables.caller_id_number ||
                      cdr.cdr.variables.last_sent_callee_id_name ||
                      "*Private Number*"
                    }}</template>
                    <!-- <v-chip style="margin-left: 10px;">{{ cdr.cdr.variables.sip_user_agent }}</v-chip> -->
                  </b-td>
                  <b-th
                    sticky-column
                    v-if="
                      !$tools.isNullOrUndefined(
                        cdr.cdr.variables.last_sent_callee_id_number
                      ) &&
                      cdr.cdr.variables.last_sent_callee_id_number !== '' &&
                      cdr.cdr.variables.last_sent_callee_id_number !==
                        cdr.cdr.variables.caller_destination
                    "
                    >{{ cdr.cdr.variables.caller_destination }} ({{
                      cdr.cdr.variables.last_sent_callee_id_number
                    }})</b-th
                  >
                  <b-th sticky-column v-else>{{
                    cdr.cdr.variables.caller_destination || "*Private Number*"
                  }}</b-th>
                  <b-td>{{
                    cdr.cdr.variables.call_direction == "local"
                      ? "internal"
                      : cdr.cdr.variables.call_direction
                  }}</b-td>
                  <b-td>{{
                    niceFormat(cdr.cdr.variables.start_epoch * 1000)
                  }}</b-td>
                  <b-td
                    ><v-chip
                      v-if="getCallMOS(cdr) !== null"
                      :small="true"
                      :style="`background: var(--${
                        getCallMOS(cdr).bg
                      }); color: #FFFFFF;`"
                    >
                      {{ getCallMOS(cdr).content }}
                    </v-chip></b-td
                  >
                  <b-td>
                    <v-chip
                      v-if="getCallStatusChip(cdr) !== null"
                      :small="true"
                      :style="`background: var(--${
                        getCallStatusChip(cdr).bg
                      }); color: #FFFFFF;`"
                    >
                      {{ getCallStatusChip(cdr).content.toUpperCase() }}
                    </v-chip>
                  </b-td>
                  <b-td v-if="cdr.recorded">
                    <b-button
                      :disabled="busyDownloading"
                      style="margin-left: 10px; padding: 5px 0 5px 6px"
                      variant="primary"
                      @click="downloadCDR(cdr)"
                    >
                      <v-icon
                        v-if="busyDownloading === cdr.id"
                        style="color: #ffffff"
                        >mdi-refresh</v-icon
                      >
                      <v-icon v-else style="color: #ffffff"
                        >mdi-download</v-icon
                      >
                    </b-button>
                  </b-td>
                  <b-td v-else></b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <p></p>
            <v-pagination
              v-if="totalCount > perPage"
              align="center"
              @input="(page) => changePage($route.params.serviceId, page)"
              v-model="currentPage"
              :total-visible="7"
              circle
              :length="knownPageCount"
              :per-page="perPage"
            ></v-pagination>
          </template>
        </template>
      </KTPortlet>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
//import Swal from "sweetalert2";
import TLib from "./lib";
import ApiService from "@/common/api.service";
import JWTService from "@/common/jwt.service";
import ImageIcon from "./personIconImage";
import MOMENT from "moment";

export default {
  components: {
    KTPortlet,
    Loader,
    personIconImage: ImageIcon,
  },
  watch: {},
  data() {
    return {
      currentPage: 1,
      knownPageCount: 1,
      totalCount: null,
      perPage: 50,
      cdrs: null,
      services: null,
      busyDownloading: null,
      selectedServiceContext: null,
      lastFullCache: 0,
    };
  },
  methods: {
    niceFormat(date) {
      return MOMENT(date).format("DD/MM/YYYY HH:mm");
    },
    getCallStatusChip(cdr) {
      if (this.$tools.isNullOrUndefined(cdr.cdr)) return null;
      if (this.$tools.isNullOrUndefined(cdr.cdr)) return null;
      if (
        this.$tools.isNullOrUndefined(cdr.cdr.variables.originate_disposition)
      ) {
        if (cdr.cdr.variables.last_app === "playback") {
          return { content: "AUDIO PLAYBACK", bg: "info" };
        }
        return { content: "ROUTING/NUMBER ERROR", bg: "danger" };
      }

      const statusAsStr = `${cdr.cdr.variables.originate_disposition}`.toUpperCase();
      switch (statusAsStr) {
        case "ATTENDED_TRANSFER":
          return { content: "CALL TRANSFERRED", bg: "success" };
        case "SUCCESS":
          return this.getCallStatus(cdr, { content: "SUCCESS", bg: "success" });
        case "ORIGINATOR_CANCEL":
          return { content: "CALL CANCELLED", bg: "info" };
        case "NO_ANSWER":
          return { content: "MISSED", bg: "warning" };
        case "UNALLOCATED_NUMBER":
          return { content: "NUMBER NOT AVAILABLE", bg: "danger" };
        case "USER_BUSY":
          return { content: "DESTINATION IS BUSY", bg: "info" };
        case "INCOMPATIBLE_DESTINATION":
          return { content: "DIALLED NUMBER NOT VALID", bg: "danger" };
        case "USER_NOT_REGISTERED":
          return { content: "EXTENSION NOT REGISTERED", bg: "danger" };
        case "FAILURE":
          return this.getCallStatus(cdr, {
            content: "CALL FAILED",
            bg: "danger",
          });
        case "NORMAL_TEMPORARY_FAILURE":
          return { content: "CALL FAILED TEMPORARILY", bg: "warning" };
        case "CALL_REJECTED":
          return { content: "CALL REJECTED BY DESTINATION", bg: "danger" };
        case "NO_USER_RESPONSE":
          return { content: "NO RESPONSE FROM DESTINATION", bg: "danger" };
      }
      return null;
      /*if (cdr.cdr.variables.call_direction === "inbound") {
        if (
          cdr.cdr.variables.answer_epoch !== "0" &&
          cdr.cdr.variables.bridge_epoch !== "0"
        ) {
          return "Answered";
        }
        if (cdr.cdr.variables.send_to_voicemail == "true") {
          return "Voicemail";
        }
        return "Missed";
      }
      if (cdr.cdr.variables.call_direction === "outbound") {
        if (
          cdr.cdr.variables.answer_epoch !== "0" &&
          cdr.cdr.variables.bridge_epoch !== "0"
        ) {
          return "Answered";
        }
        if (
          cdr.cdr.variables.answer_epoch !== "0" &&
          cdr.cdr.variables.bridge_epoch !== "0"
        ) {
          return "Answered";
        }
      }*/
    },
    getCallMOS(cdr) {
      if (cdr.cdr.callStats === undefined) return null;
      if (cdr.cdr.callStats.audio === undefined) return null;
      if (cdr.cdr.callStats.audio.inbound === undefined) return null;
      let cqi = null;
      if (cdr.cdr.callStats.audio.inbound.quality_percentage !== undefined) {
        cqi = cdr.cdr.callStats.audio.inbound.quality_percentage;
      } else if (cdr.cdr.callStats.audio.inbound.mos !== undefined) {
        cqi = Number.parseInt(
          ((cdr.cdr.callStats.audio.inbound.mos / 5) * 100).toFixed(0)
        );
      }
      if (cqi === null) return null;
      if (cqi >= 90) return { content: "A+", bg: "success" };
      if (cqi >= 80) return { content: "A", bg: "success" };
      if (cqi >= 70) return { content: "B", bg: "warning" };
      return { content: "F", bg: "danger" };
    },
    getCallStatus(cdr, defKey) {
      if (cdr.cdr.variables.call_direction === "inbound") {
        if (
          cdr.cdr.variables.answer_epoch !== "0" &&
          cdr.cdr.variables.bridge_epoch !== "0"
        ) {
          return { content: "Answered", bg: "success" };
        }
        if (cdr.cdr.variables.send_to_voicemail == "true") {
          return { content: "Voicemail", bg: "success" };
        }
        return { content: "Missed", bg: "warning" };
      }
      if (cdr.cdr.variables.call_direction === "outbound") {
        if (
          cdr.cdr.variables.answer_epoch !== "0" &&
          cdr.cdr.variables.bridge_epoch !== "0"
        ) {
          return { content: "Answered", bg: "success" };
        }
      }
      if (cdr.cdr.variables.call_direction === "local") {
        if (
          cdr.cdr.variables.answer_epoch !== "0" &&
          cdr.cdr.variables.bridge_epoch !== "0"
        ) {
          return { content: "Answered", bg: "success" };
        }
      }
      return defKey;
    },
    changePage(serviceId, to) {
      this.$data.currentPage = to;

      if (`${this.currentPage}` !== `${this.$route.query.page}`) {
        if (this.currentPage === 1)
          this.$router.push(`/Telecoms/${serviceId}/CallDetailRecords`);
        else
          this.$router.push(
            `/Telecoms/${serviceId}/CallDetailRecords?page=${this.currentPage}`
          );
      }

      this.updateCDRs(serviceId);
    },
    downloadCDR(cdr) {
      let self = this;
      self.$data.busyDownloading = cdr.id;
      ApiService.triggerDownloadFile(
        `/telecoms/${JWTService.getActiveCrmID()}/${
          self.$route.params.serviceId
        }/cdrs/${cdr.id}/recording`,
        `${cdr.id}.wav`
      )
        .then(() => {
          self.$data.busyDownloading = null;
        })
        .catch((x) => {
          self.$log.error(x);
          self.$data.busyDownloading = null;
          self.$log.error("Error trying to download file");
        });
    },
    updateCDRs(serviceId, pageToCache = undefined, silent = false) {
      //this.$router.push(`/Telecoms/CallDetailRecords/${serviceId}`);
      let self = this;
      if (!self.$tools.isNullOrUndefined(serviceId)) {
        ApiService.getWithCache(
          self,
          "crm",
          "services",
          "crm:services"
          //bumpacache
        )
          .then((data) => {
            if (!self.$tools.isNullOrUndefined(serviceId)) {
              for (let service of data) {
                if (service.id == serviceId) {
                  for (let attr of service.attributes || []) {
                    if (attr.key === "freeswitchdomain") {
                      self.$data.selectedServiceContext = attr.value;
                      return;
                    }
                  }
                  return;
                }
              }
            }
          })
          .catch((response) => {
            self.$log.error(response);
          });
      }
      if (pageToCache === undefined) {
        if (!silent) {
          self.$data.cdrs = [];
          self.$nextTick(() => {
            self.$data.cdrs = null;
          });
        }
        if (self.totalCount === null) {
          self.knownPageCount = 1;
          TLib.getCDRS(
            self,
            serviceId,
            silent,
            {
              limit: self.perPage,
              offset: (self.currentPage - 1) * self.perPage,
            },
            "count"
          )
            .then((cdrss) => {
              self.totalCount = cdrss.count;
              self.knownPageCount = Math.ceil(cdrss.count / self.perPage);
            })
            .catch((x) => {
              self.$log.error(x);
              self.$log.error("Error loading content");
            });
        }
      }
      //self.$data.selectedServiceId = `${serviceId}`;
      TLib.getCDRS(self, serviceId, silent, {
        limit: self.perPage,
        offset: ((pageToCache || self.currentPage) - 1) * self.perPage,
      })
        .then((cdrss) => {
          if (pageToCache !== undefined) return;
          self.$scrollToTop();
          self.$nextTick(() => {
            if (silent) {
              self.$data.cdrs = [];
              self.$nextTick(() => {
                self.$data.cdrs = cdrss;
              });
            }
            self.$data.cdrs = cdrss;
            if (self.currentPage * self.perPage > self.totalCount) return;
            self.updateCDRs(serviceId, self.currentPage + 1);
          });
        })
        .catch((x) => {
          self.$log.error(x);
          self.$log.error("Error loading content");
        });
    },
    /*resync() {
      let self = this;
      ApiService.getWithCache(
        self,
        "crm",
        "services",
        "crm:services"
        //bumpacache
      )
        .then((data) => {
          self.$data.services = data;
          if (!self.$tools.isNullOrUndefined(self.$route.params.serviceId)) {
            for (let service of data) {
              if (service.id == self.$route.params.serviceId) {
                if (service.status !== 1) continue;
                return self.updateCDRs(service.id);
              }
            }
          }

          // select first one
          for (let service of data) {
            if ([39].indexOf(service.servicePlanId) >= 0) {
              if (service.status !== 1) continue;
              return self.updateCDRs(service.id);
            }
          }
        })
        .catch((response) => {
          self.$log.error(response);
        });
    },*/
  },
  computed: {
    cdrsFiltered() {
      let rList = [];

      for (let cdr of this.cdrs)
        if (!this.$tools.isNullOrUndefined(cdr.cdr.variables.originate_disposition))
          rList.push(cdr);

      return rList;
    },

    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    /*serviceDomains() {
      if (this.$tools.isNullOrUndefined(this.services)) return [];

      let list = [];
      for (let service of this.services) {
        if ([39].indexOf(service.servicePlanId) >= 0) {
          if (this.$tools.isNullOrUndefined(service)) continue;
          if (service.status !== 1) continue;

          let propsList = service.note.split(" ");

          let domainname = null;
          for (let prop of propsList) {
            if (prop.indexOf("NAME:") === 0) {
              domainname = prop.split(":")[1].trim();
              break;
            }
          }

          if (this.$tools.isNullOrUndefined(domainname)) continue;
          list.push({ text: domainname, value: `${service.id}` });
        }
      }
      return list;
    },*/
  },
  beforeDestroy() {
    this.$eventBus.emit("ws-send", {
      action: "UNSUBSCRIBE",
      data: "telecoms-cdr",
    });
    this.$eventBus.off(`ws-telecoms-cdr`);
    this.$eventBus.off(`switch-service-selected`);
    //this.$eventBus.off(`cache-crm:services`);
  },
  mounted() {
    let self = this;
    self.$eventBus.on("ws-telecoms-cdr", (ev) => {
      if (self.selectedServiceContext == null) return;
      if (self.selectedServiceContext != ev.domain) return;

      const safeDist = new Date().getTime() - 1000 * 5;
      if (self.lastFullCache >= safeDist) {
        //console.log("FORCE CACHE FLUSH: NO");
        return;
      }
      self.$data.lastFullCache = new Date().getTime();
      //console.log("FORCE CACHE FLUSH");
      self.updateCDRs(self.$route.params.serviceId, undefined, true);
    });
    self.$eventBus.emit("ws-send", {
      action: "SUBSCRIBE",
      data: "telecoms-cdr",
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BetterTelecoms" },
      { title: "Caller Detail Records" },
    ]);

    try {
      let selectedPage = Number.parseInt(this.$route.query.page || "1");
      this.changePage(this.$route.params.serviceId, selectedPage);
    } catch (xx) {
      this.changePage(this.$route.params.serviceId, 1);
    }

    self.$eventBus.on(`switch-service-selected`, (x) => {
      self.$data.totalCount = null;
      self.changePage(x, 1);
    });
    //self.$eventBus.on(`cache-crm:services`, () => self.resync(false));
    //self.resync();
  },
};
</script>
